<template>
  <div v-if="entity !== null && hasPerm('agenda.view_activitydate')">
    <div>
      <div class="field-group-block">
        <div class="field-group-header">
          <b-row>
            <b-col cols="6">
              <b>Agenda</b>
            </b-col>
            <b-col cols="6" class="text-right">
              <a
                class="btn btn-secondary btn-sm"
                href
                @click.prevent="printAgenda()"
              >
                <i class="fas fa-file-pdf"></i> Pdf
              </a>
              &nbsp;
              <a
                class="btn btn-secondary btn-sm"
                href
                :class="{ disabled: dates.length === 0 }"
                @click.prevent="excelAgenda()"
              >
                <i class="fas fa-file-excel"></i> Excel
              </a>
              &nbsp;
              <a class="btn-sm btn btn-secondary" href @click.prevent="onAddEvent">
                <i class="fa fa-plus"></i> Ajouter
              </a>
              &nbsp;
              <a
                class="btn-sm btn btn-secondary"
                href
                @click.prevent="onChangePlace"
                :class="{ disabled: !isForcePlaceActive, }"
              >
                <i class="fa fa-location-dot"></i> Modifier le lieu
              </a>
              &nbsp;
              <a
                class="btn-sm btn btn-secondary"
                href
                @click.prevent="onDeletePlaces"
                :class="{ disabled: !isForcePlaceActive, }"
              >
                <i class="fa fa-location-dot"></i> Supprimer les dates
              </a>
            </b-col>
          </b-row>
        </div>
        <div ref="entityAgendaTab">
          <div class="hide-here"><b>{{ entity.name }}</b></div>
          <agenda-dates-table
            :entity="entity"
            :show-header="false"
            :force-add-event="forceAddEvent"
            :force-change-places="forceChangePlace"
            :delete-change-places="deleteChangePlaces"
            @forced="onForceDone()"
            @selected="onSelectionDone($event)"
            @agenda-dates-changed="onDatesChanged"
          ></agenda-dates-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgendaDatesTable from '@/components/Agenda/AgendaDatesTable.vue'
import { BackendMixin } from '@/mixins/backend'
import { AgendaMixin } from '@/mixins/agenda'
import { slugify } from '@/utils/strings'
import { openDocument } from '@/utils/http'

export default {
  name: 'entity-agenda',
  components: {
    AgendaDatesTable,
  },
  mixins: [BackendMixin, AgendaMixin],
  props: {
    entity: Object,
  },
  data() {
    return {
      dates: [],
    }
  },
  computed: {
  },
  created() {
  },
  watch: {
  },
  methods: {
    async printAgenda() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = slugify('agenda-' + this.entity.name)
      const docContent = this.$refs.entityAgendaTab.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelAgenda() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify('agenda-' + this.entity.name)
      const docContent = this.$refs.entityAgendaTab.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDatesChanged(event) {
      this.dates = event.dates
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
