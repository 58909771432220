import { SchoolYear, makeSchoolYear } from './schools'

export class IndividualLinkDocument {
  constructor(
    public id: number,
    public schoolYear: SchoolYear,
    public uploadOn: Date|null,
    public saveOn: Date|null
  ) {
  }
}

export function makeIndividualLinkDocument(jsonData: any = null): IndividualLinkDocument {
  if (jsonData === null) {
    jsonData = {}
  }
  const documentElt = jsonData.document || null
  return new IndividualLinkDocument(
    documentElt ? documentElt.id : 0,
    makeSchoolYear(jsonData.school_year),
    documentElt ? documentElt.upload_on : null,
    documentElt ? documentElt.save_on : null
  )
}

export enum TaxYearlyCertificateAgeRule {
  LessThanSevenOnSeance = 1,
  SevenOrLessOnDec31 = 2,
  LessThanSevenOnDec31 = 3
}

export class TaxYearlyCertificateRuleLunchPrice {
  constructor(
    public id: number,
    public lunchPrice: number,
    public familyLevel: number
  ) {
  }
}

export class TaxYearlyCertificateRule {
  constructor(
    public year: number,
    public lunchPrice: number,
    public ageRule: TaxYearlyCertificateAgeRule,
    public childByChild: boolean,
    public paid: boolean,
    public strictPayments: boolean,
    public familyLevelLunchPrice: boolean,
    public lunchPrices: TaxYearlyCertificateRuleLunchPrice[]
  ) {
  }
}

export function makeTaxYearlyCertificateRuleLunchPrice(jsonData: any = null): TaxYearlyCertificateRuleLunchPrice {
  if (jsonData === null) {
    jsonData = {}
  }
  return new TaxYearlyCertificateRuleLunchPrice(
    jsonData.id,
    jsonData.lunch_price,
    jsonData.family_level
  )
}

export function makeTaxYearlyCertificateRule(jsonData: any = null): TaxYearlyCertificateRule {
  if (jsonData === null) {
    jsonData = {}
  }
  return new TaxYearlyCertificateRule(
    jsonData.year,
    jsonData.lunch_price,
    jsonData.age_rule,
    !!jsonData.child_by_child,
    !!jsonData.paid,
    !!jsonData.strict_payments,
    !!jsonData.family_level_lunch_price,
    (jsonData.lunch_prices || []).map(makeTaxYearlyCertificateRuleLunchPrice)
  )
}

export class Sending {
  constructor(
    public sentOn: Date,
    public sentBy: string
  ) {
  }
}

export function makeSending(jsonData: any = null): Sending {
  if (jsonData === null) {
    jsonData = {}
  }
  return new Sending(
    jsonData.sent_on,
    jsonData.sent_by
  )
}
