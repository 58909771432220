<template>
  <div v-if="family && hasPerm('families.view_family')">
    <div class="field-group-block info-block-family">
      <div v-if="!showForm">
        <div class="field-group-header">
          <b-row>
            <b-col cols="9">
              <b>Données Famille</b>
            </b-col>
            <b-col cols="3" class="text-right">
              <a
                v-if="hasPerm('families.change_family') && !readOnly"
                class="btn btn-primary btn-sm edit-family-button"
                href
                @click.prevent="onEdit()"
                :class="[editDisabled ? 'disabled' : '']"
              >
                <i class="fa fa-edit"></i> Modifier
              </a>
            </b-col>
          </b-row>
        </div>
        <div class="field-line">
          <b>Régime social:</b> {{ family.socialRegime.name|defaultValue('-') }}
        </div>
        <div class="field-line">
          <b>Numéro <span v-if="family.socialRegime">{{ family.socialRegime.name }}</span>:</b>
          {{ family.cafNumber }}
        </div>
        <div class="field-line" v-if="family.socialRegime.proAccess">
          <b>Autorise l'accès au dossier {{ family.socialRegime.getProAccessName() }}:
          </b> {{ family.allowCafProAccess|yesno }}
        </div>
        <div class="field-line" v-if="levels.length">
          <b>Quotient Familial:</b>
          <b-row>
            <b-col :cols="showAll ? 8 : 12">
              <div v-for="level in levels" :key="level.id">
                <div v-if="level.value !== null || level.order === 1">
                  <b>QF{{ level.order }} :</b>
                  {{ level.value|defaultValue('non défini') }}
                  <span class="badge badge-light" v-if="level.updatedOn">
                    MAJ {{ level.updatedOn|dateToString('DD/MM/YYYY') }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col :cols="showAll ? 2 : 6">
              <span
                v-if="hasPerm('families.change_familylevel') && family.allowCafProAccess && family.cafNumber"
              >
                <family-load-data-button
                  :family-id="family.entity.id"
                  @refresh="onRefresh"
                  v-if="family.allowCafProAccess && family.cafNumber"
                ></family-load-data-button>
                <div
                  v-if="family.cafUpdateMessage"
                  class="caf-update-message"
                  :class="family.cafUpdateMessage === 'ok' ? 'success' : 'error'"
                >
                  {{ family.cafUpdateMessage }}
                </div>
                <div v-if="family.socialRegime.proAccess && family.socialRegime.proAccessUrl">
                  <a :href="family.socialRegime.proAccessUrl" target="_blank" class="btn btn-xs btn-secondary">
                    <i class="fa fa-link"></i> {{ family.socialRegime.getProAccessName() }}
                  </a>
                </div>
              </span>
            </b-col>
            <b-col :cols="showAll ? 2 : 6" class="text-right">
              <historical-qf-modal :family="family" :levels="levels" class="link-historical-qf">
              </historical-qf-modal>
            </b-col>
          </b-row>
        </div>
        <div class="field-line">
          <b>Accord RGPD:</b> {{ family.personalDataAgreementDate|dateToString('DD/MM/YYYY') }}
        </div>
        <div class="field-line" v-if="!family.hideMeetingsNotificationByEmail">
          <b>Convocation à l'AG par email:</b> {{ family.acceptMeetingsNotificationByEmail|yesno }}
        </div>
        <fields-detail
          :show-entity="true"
          :read-only="false"
          :role="null"
          :individual="null"
          :entity="entity"
          :is-family="!!family"
          :included="true"
          :included-in-address="false"
        >
        </fields-detail>
      </div>
      <div v-if="showForm">
        <b-form @submit.prevent="isAgreeRgpd()">
          <div class="field-group-header">
            <b-row>
              <b-col cols="9">
                <b>Données Famille</b>
              </b-col>
            </b-row>
          </div>
          <div class="field-line">
            <b-row>
              <b-col>
                <b-form-group
                  id="social-regime-group"
                  label="Régime social"
                  label-for="socialRegime"
                  description=""
                >
                  <b-form-select id="socialRegime" v-model="familyData.socialRegime" :rel="familyData.socialRegime.id">
                    <b-form-select-option
                      :value="socialRegime"
                      v-for="socialRegime in socialRegimes"
                      :key="socialRegime.id"
                    >
                      {{ socialRegime.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="field-line" v-if="familyData.socialRegime && familyData.socialRegime.id">
            <b-row>
              <b-col>
                <b-form-group
                  id="caf-number-group"
                  :label="'Numéro ' + familyData.socialRegime.name"
                  label-for="cafNumber"
                  description=""
                >
                 <b-form-input
                    id="cafNumber"
                    v-model="familyData.cafNumber"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div
            class="field-line"
            v-if="familyData.socialRegime && familyData.socialRegime.id && familyData.socialRegime.proAccess"
          >
            <b-row>
              <b-col>
                <b-form-group
                  :description="'cocher si la personne autorise l\'accès à son dossier ' + familyData.socialRegime.getProAccessName()"
                >
                  <b-form-checkbox
                    id="allowCafProAccess"
                    v-model="familyData.allowCafProAccess"
                    name="allowCafProAccess"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Accès au dossier {{ familyData.socialRegime.getProAccessName() }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="field-line">
            <b-row v-if="levels.length > 0">
              <b-col>
                <b-form-group
                  id="level1-group"
                  :label="'Quotient Familial' + ((levels.length > 1) ? ' 1' : '')"
                  label-for="level1"
                  description=""
                >
                  <b-form-input
                    id="level1"
                    v-model="level1"
                    type="number"
                    :required="!allowEmptyLevel"
                    @change="onLevel1Changed()"
                  ></b-form-input>
                </b-form-group>
                <div v-if="allowEmptyLevel  && !level1" class="warning-text">
                  Le quotient familial n'est pas saisi.
                </div>
                <div v-if="levels.length > 1">
                  <b-form-checkbox
                    id="level-propagate"
                    v-model="propagateLevel"
                    name="level-propagate"
                    :value="true"
                    :unchecked-value="false"
                    size="sm"
                    class="level-propagate"
                  >
                    Mettre à jour tous les QF
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col v-if="levels.length > 1">
                <b-form-group
                  id="level2-group"
                  label="Quotient Familial 2"
                  label-for="level2"
                  description=""
                >
                  <b-form-input
                    id="level2"
                    v-model="level2"
                    type="number"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col v-if="levels.length > 2">
                <b-form-group
                  id="level3-group"
                  label="Quotient Familial 3"
                  label-for="level3"
                  description=""
                >
                  <b-form-input
                    id="level3"
                    v-model="level3"
                    type="number"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="field-line">
            <b-row>
              <b-col cols="8">
                <b-form-group
                  id="personalDataAgreementDate-group"
                  label="Date accord RGPD"
                  label-for="personalDataAgreementDate"
                  description=""
                >
                  <b-form-input
                    id="personalDataAgreementDate"
                    v-model="familyData.personalDataAgreementDate"
                    type="date"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  id="personalDataAgreementToday-group"
                  label="Définir la date"
                  class="text-right"
                  description="">
                  <a @click.prevent="setRgpdDateToday()" class="btn btn-sm btn-secondary">
                    Aujourd'hui
                  </a>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="field-line" v-if="!family.hideMeetingsNotificationByEmail">
            <b-row>
              <b-col>
                <b-form-group
                  description="cocher si la personne accepte d'être convoquée à l'AG par email"
                >
                  <b-form-checkbox
                    id="acceptMeetingsNotificationByEmail"
                    v-model="familyData.acceptMeetingsNotificationByEmail"
                    name="acceptMeetingsNotificationByEmail"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Convocation à l'AG par email
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <fields-detail
            :show-entity="true"
            :read-only="false"
            :role="null"
            :individual="null"
            :entity="entity"
            :is-family="!!family"
            :included="true"
            :included-in-address="false"
            :force-edit="true"
            :force-save="forceSave"
            @saved="onFieldsSaved"
          >
          </fields-detail>
          <b-row class="buttons-bar">
            <b-col class="text-left">
            </b-col>
            <b-col class="text-right">
              <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">Annuler</a>
              <b-button class="e2et-submit-button" type="submit" variant="primary">Enregistrer</b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <confirm-modal
      name="agree-rgpd"
      title="Accord RGPD"
      :text="'Souhaitez-vous que Millibase renseigne la date d’accord RGPD ?'"
      @confirmed="setRgpdDateTodayViaModal()"
      @cancelled="onSave()"
    ></confirm-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import FieldsDetail from '@/components/Fields/FieldsDetail'
import HistoricalQfModal from '@/components/Family/HistoricalQfModal'
import FamilyLoadDataButton from '@/components/Family/FamilyLoadDataButton'
import confirmModal from '@/components/Modals/ConfirmModal'
import { BackendMixin } from '@/mixins/backend'
import { makeFamily, makeSocialRegime } from '@/types/families'
import { BackendApi } from '@/utils/http'

export default {
  name: 'family-detail',
  components: {
    FieldsDetail,
    FamilyLoadDataButton,
    HistoricalQfModal,
    confirmModal,
  },
  mixins: [BackendMixin],
  props: {
    showAll: {
      type: Boolean,
      defaultValue: false,
    },
    readOnly: Boolean,
    family: Object,
  },
  data() {
    return {
      familyData: null,
      level1: null,
      level2: null,
      level3: null,
      socialRegimes: [],
      propagateLevel: true,
      forceSave: false,
    }
  },
  computed: {
    showForm() {
      return store.getters.editMode === 'family'
    },
    editDisabled() {
      return store.getters.editMode !== ''
    },
    entity() {
      return this.family.entity
    },
    allowEmptyLevel() {
      return store.getters.allowEmptyLevel
    },
    levels() {
      const familyLevelsCount = store.getters.familyLevelsCount
      const levels = this.family.levels.slice(0, familyLevelsCount)
      for (let index = 0; index < levels.length; index++) {
        if (levels[index].id === 0) {
          levels[index].id = -index
        }
      }
      return levels
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    async onEdit() {
      if (!this.editDisabled) {
        this.setEditMode('family')
        this.familyData = { ...this.family, }
        this.level1 = this.levels.length >= 1 ? this.levels[0].value : null
        this.level2 = this.levels.length >= 2 ? this.levels[1].value : null
        this.level3 = this.levels.length >= 3 ? this.levels[2].value : null
        await this.loadSocialRegimes()
      }
    },
    setRgpdDateToday() {
      this.familyData.personalDataAgreementDate = moment().format('YYYY-MM-DD')
    },
    setRgpdDateTodayViaModal() {
      this.familyData.personalDataAgreementDate = moment().format('YYYY-MM-DD')
      this.onSave()
    },
    onCancel() {
      this.setEditMode('')
    },
    onRefresh(evt) {
      this.$emit('changed', { family: makeFamily(evt.familyData), })
    },
    async loadSocialRegimes() {
      this.startLoading('family')
      let backendApi = new BackendApi('get', '/api/families/social-regimes/')
      try {
        let resp = await backendApi.callApi()
        let socialRegimes = [makeSocialRegime()]
        this.socialRegimes = socialRegimes.concat(resp.data.map(elt => makeSocialRegime(elt)))
        if (this.familyData.socialRegime.id === 0) {
          let defaultValues = this.socialRegimes.filter(elt => elt.isDefault)
          if (defaultValues.length > 0) {
            this.familyData.socialRegime = defaultValues[0]
          }
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading('family')
    },
    async isAgreeRgpd() {
      if (this.familyData.personalDataAgreementDate === null) {
        this.$bvModal.show('bv-confirm-modal:agree-rgpd')
      } else {
        await this.onSave()
      }
    },
    async onSave() {
      this.startLoading('family')
      let levels = [this.level1 || null, this.level2 || null, this.level3 || null]
      const data = {
        family: {
          caf_number: this.familyData.cafNumber,
          allow_caf_pro_access: this.familyData.allowCafProAccess,
          accept_meetings_notification_by_email: this.familyData.acceptMeetingsNotificationByEmail,
          rgpd_agreement_date: this.familyData.personalDataAgreementDate || null,
          social_regime: (this.familyData.socialRegime ? this.familyData.socialRegime.id : null) || null,
          levels: levels,
        },
      }
      // Use the entity Id for this API
      let backendApi = new BackendApi('patch', '/api/families/families/' + this.entity.id + '/')
      try {
        let resp = await backendApi.callApi(data)
        this.$emit('changed', { family: makeFamily(resp.data), })
        await this.addSuccess('La famille a été enregistrée')
        this.forceSave = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading('family')
    },
    onFieldsSaved() {
      this.forceSave = false
      this.setEditMode('')
    },
    onLevel1Changed() {
      if (this.propagateLevel || !this.level2) {
        this.level2 = this.level1
      }
      if (this.propagateLevel || !this.level3) {
        this.level3 = this.level1
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.level-propagate {
  color: #888;
}
.caf-update-message {
  font-size: 12px;
  padding: 2px;
  text-align: center;
}
.caf-update-message.success {
  background: #abf197;
}
.caf-update-message.error {
  background: #f69b65;
}
</style>
